import axios from "axios";
import { getToken } from "../utility/Utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 50000,
  headers: {},
});

// Request interceptor to set Authorization header
instance.interceptors.request.use(
  (config) => {
    const authToken = getToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Logout the user, clear token or perform any other logout actions
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export const get = async (url, params) => {
  return instance
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch(async (error) => {
      throw error;
    });
};

export const post = async (url, params, body) => {
  return instance
    .post(url, body, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch(async (error) => {
      throw error;
    });
};

export const put = async (url, params, body) => {
  return instance
    .put(url, body, {
      params,
    })
    .then((res) => {
      return res.data;
    })

    .catch(async (error) => {
      throw error;
    });
};

export const dlt = async (url, params, body) => {
  return instance
    .delete(url, body, {
      params,
    })
    .then((res) => {
      return res.data;
    })

    .catch(async (error) => {
      throw error;
    });
};

export const patch = async (url, params, body) => {
  return instance
    .patch(url, body, {
      params,
    })
    .then((res) => {
      return res.data;
    })

    .catch(async (error) => {
      throw error;
    });
};
