import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dlt, get, put, post, patch } from "../api";
import { openAlert } from "./alert";

// Initial state
const initialState = {
  users: [],
  loading: false,
  error: null,
  page: 1,
  hasMore: true,

  subusers: {
    list: [],
    count: 0,
    mainAdmin: null,
    userStats: null,
    hasMore: true,
  },
  subuserLoading: false,
  subuserError: null,

  addedUser: null,
  addNewUserLoading: false,
  addNewUserSuccess: false,
  addNewUserError: null,

  updatedUser: null,
  updatedUserLoading: false,
  updatedUserError: null,

  updatedSubUser: null,
  updatedSubUserLoading: false,
  updatedSubUserError: null,

  restrictUsers: null,
  restrictUsersLoading: false,
  restrictUsersError: null,

  deleteUser: null,
  deleteUserLoading: false,
  deleteUserError: null,

  extendUserFreeTrial: null,
  extendUserFreeTrialSuccess: false,
  extendUserFreeTrialLoading: false,
  extendUserFreeTrialError: null,
};

export const userList = createAsyncThunk(
  "userList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await get(
        `/adminpanel/userlist?page=${params.page}&page_size=${params.page_size}&search=${params.search}&plan_details=${params.plan}&is_active=${params.is_active}&ordering=${params.ordering}`,
        ""
      );
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user list data"
      );
    }
  }
);

// Async thunk for fetching subusers
export const subuserList = createAsyncThunk(
  "subuserList",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await get(
        `/adminpanel/subuserlist/admin/${params.id}/subusers?page=${params.page}&page_size=${params.page_size}`,
        ""
      );
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message,
          severity: "danger",
          title: "Error",
        })
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch subuser list data"
      );
    }
  }
);

export const addNewUser = createAsyncThunk(
  "addNewUser",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/adminpanel/enterpriseuseradd/`,
        "",
        userData
      );
      dispatch(
        openAlert({
          message: "Updated user details",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      if (error.response.data.length > 0) {
        dispatch(
          openAlert({
            message: error.response.data[0],
            severity: "danger",
            title: "Error",
          })
        );
      } else {
        dispatch(
          openAlert({
            message: "Error while updating user details",
            severity: "danger",
            title: "Error",
          })
        );
      }
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, userData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await patch(
        `/adminpanel/adminuseredit/${id}/`,
        "",
        userData
      );
      dispatch(
        openAlert({
          message: "Updated user details",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      if (error.response.data.email[0]) {
        dispatch(
          openAlert({
            message: error.response.data.email[0],
            severity: "danger",
            title: "Error",
          })
        );
      } else {
        dispatch(
          openAlert({
            message: error.response.data.email[0],
            severity: "danger",
            title: "Error",
          })
        );
      }
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const updateSubUser = createAsyncThunk(
  "updateSubUser",
  async ({ id, userData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(
        `/adminpanel/adminuseredit/${id}/`,
        "",
        userData
      );
      dispatch(
        openAlert({
          message: "Updated sub-user details",
          severity: "success",
          title: "Success",
        })
      );
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error while updating sub-user details",
          severity: "danger",
          title: "Error",
        })
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await dlt(`/adminpanel/adminuserdelete/${id}/`, "", "");
      dispatch(
        openAlert({
          message: "User deleted successfully",
          severity: "success",
          title: "Success",
        })
      );
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error while deleting user",
          severity: "danger",
          title: "Error",
        })
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete user data"
      );
    }
  }
);

export const restrictUser = createAsyncThunk(
  "restrictUser",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(`/adminpanel/toggle/${id}/`, "");
      setTimeout(() => {
        if (response?.detail?.includes("Restricted")) {
          dispatch(
            openAlert({
              message: "User restricted successfully",
              severity: "success",
              title: "Success",
            })
          );
        } else {
          dispatch(
            openAlert({
              message: "User activated successfully",
              severity: "success",
              title: "Success",
            })
          );
        }
      }, 1000);
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error",
          severity: "danger",
          title: "Error",
        })
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

export const extendUserFreetrial = createAsyncThunk(
  "extendUserFreetrial",
  async ({ id, days_to_extend }, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(
        `/adminpanel/freetrialextendbyndays/${id}/`,
        "",
        { days_to_extend }
      );
      dispatch(
        openAlert({
          message: "User free trial update successfully",
          severity: "success",
          title: "Success",
        })
      );
      return response;
    } catch (error) {
      dispatch(
        openAlert({
          message: "Error",
          severity: "danger",
          title: "Error",
        })
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to update user data"
      );
    }
  }
);

// Slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetUsers(state) {
      state.users = [];
      state.page = 1;
      state.hasMore = true;
    },
    emptyExtendFreeTrial(state) {
      state.extendUserFreeTrialSuccess = false;
    },
    emptyAddNewUser(state) {
      state.addNewUserSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Cases for userList
      .addCase(userList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.loading = false;
        const { results, next, previous } = action.payload;

        let currentPage = 1;

        if (next) {
          currentPage = parseInt(new URL(next).searchParams.get("page")) - 1;
        } else if (previous) {
          currentPage =
            parseInt(new URL(previous).searchParams.get("page")) + 1;
        } else {
          currentPage = 1;
        }
        // Update state based on the current page
        if (currentPage === 1) {
          state.users = results; // Reset users for page 1
        } else {
          state.users = [...state.users, ...results]; // Append users for other pages
        }

        state.page = currentPage; // Update the current page in state
        state.hasMore = Boolean(next); // Check if more data is available
      })

      // Cases for subuserList
      .addCase(subuserList.pending, (state) => {
        state.subuserLoading = true;
        state.subuserError = null;
      })
      .addCase(subuserList.fulfilled, (state, action) => {
        state.subuserLoading = false;
        const { adminSubUsers, mainAdmin, userStats, next, previous, count } =
          action.payload;
        state.subusers.mainAdmin = mainAdmin;
        state.subusers.count = count;
        state.subusers.userStats = userStats;
        state.subusers.hasMore = Boolean(next);

        let currentPage = 1;

        if (next) {
          currentPage = parseInt(new URL(next).searchParams.get("page")) - 1;
        } else if (previous) {
          currentPage =
            parseInt(new URL(previous).searchParams.get("page")) + 1;
        } else {
          currentPage = 1;
        }

        // Update state based on the current page
        if (currentPage === 1) {
          state.subusers.list = adminSubUsers;
        } else {
          state.subusers.list = [...state.subusers.list, ...adminSubUsers];
        }
      })
      .addCase(subuserList.rejected, (state, action) => {
        state.subuserLoading = false;
        state.subuserError = action.payload || "Something went wrong";
      })

      // Cases to update user
      .addCase(addNewUser.pending, (state) => {
        state.addNewUserLoading = true;
        state.addNewUserError = null;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        // let newUserData = action.payload;
        // newUserData = {
        //   ...newUserData,
        //   email: newUserData.master_admin_email,
        //   subuser_count: 0,
        //   price_per_month: newUserData.price,
        //   total_admin_users: newUserData.total_admin_user,
        //   total_member_users: newUserData.total_normal_user,
        // };

        state.addNewUserLoading = false;
        state.addNewUserSuccess = true;
        state.addedUser = action.payload;
        // state.users = [...state.users, newUserData];
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.addNewUserLoading = false;
        state.addNewUserError = action.payload || "Something went wrong";
      })

      // Cases to update user
      .addCase(updateUser.pending, (state) => {
        state.updatedUserLoading = true;
        state.updatedUserError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const updateId = action.payload.id;

        state.updatedUserLoading = false;
        state.updatedUser = action.payload;
        state.users = state.users.map((user) => {
          if (user.id === updateId) {
            return {
              ...user,
              full_name: action.payload.full_name,
              email: action.payload.email,
              total_admin_users: action.payload.total_admin_user,
              total_member_users: action.payload.total_normal_user,
              price_per_month: action.payload.price_per_month,
              next_billing_date: action.payload.next_billing_date,
            };
          } else {
            return user;
          }
        });
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updatedUserLoading = false;
        state.updatedUserError = action.payload || "Something went wrong";
      })

      // Cases to update sub user
      .addCase(updateSubUser.pending, (state) => {
        state.updatedSubUserLoading = true;
        state.updatedSubUserError = null;
      })
      .addCase(updateSubUser.fulfilled, (state, action) => {
        state.updatedSubUserLoading = false;
        const updateId = action.meta.arg.id;

        state.subusers.list = state.subusers.list.map((user) => {
          if (user.id === updateId) {
            return {
              ...user,
              full_name: action.payload.full_name,
              email: action.payload.email,
            };
          } else {
            return user;
          }
        });
        state.updatedSubUser = action.payload;
      })
      .addCase(updateSubUser.rejected, (state, action) => {
        state.updatedSubUserLoading = false;
        state.updatedSubUserError = action.payload || "Something went wrong";
      })

      // Cases to Restrict user
      .addCase(restrictUser.pending, (state) => {
        state.restrictUsersLoading = true;
        state.restrictUsersError = null;
      })
      .addCase(restrictUser.fulfilled, (state, action) => {
        state.restrictUsersLoading = false;

        const restrictedUserId = action.payload.data.mainAdmin.id;
        const isActive = action.payload.data.mainAdmin.is_active;
        state.users = state.users.map((user) => {
          if (user.id === restrictedUserId) {
            return { ...user, is_active: isActive };
          } else {
            return user;
          }
        });
        state.subusers.list = state.subusers.list.map((user) => {
          if (user.id === restrictedUserId) {
            return { ...user, is_active: isActive };
          } else {
            return user;
          }
        });
      })
      .addCase(restrictUser.rejected, (state, action) => {
        state.restrictUsersLoading = false;
        state.restrictUsersError = action.payload || "Something went wrong";
      })

      // Cases to DELETE user
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserLoading = true;
        state.deleteUserError = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const deleteId = action.meta.arg.id;

        state.deleteUserLoading = false;
        state.deleteUser = action.payload;
        state.users = state.users.filter((user) => user.id !== deleteId);
        state.subusers.list = state.subusers.list.filter(
          (user) => user.id !== deleteId
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserError = action.payload || "Something went wrong";
      })

      // Cases to EXTEND user FREE TRIAL
      .addCase(extendUserFreetrial.pending, (state) => {
        state.extendUserFreeTrialLoading = true;
        state.extendUserFreeTrialSuccess = false;
        state.extendUserFreeTrialError = null;
      })
      .addCase(extendUserFreetrial.fulfilled, (state, action) => {
        const extendUserEmail = action.payload.email;
        const extendDate = action.payload.next_renew_date;
        state.extendUserFreeTrialLoading = false;
        state.extendUserFreeTrialSuccess = true;
        state.extendUserFreeTrial = action.payload;
        state.users = state.users.map((user) => {
          if (user.email === extendUserEmail) {
            return { ...user, next_renew_date: extendDate };
          } else {
            return user;
          }
        });
      })
      .addCase(extendUserFreetrial.rejected, (state, action) => {
        state.extendUserFreeTrialLoading = false;
        state.extendUserFreeTrialSuccess = false;
        state.extendUserFreeTrialError =
          action.payload || "Something went wrong";
      });
  },
});

// Export the reducer
export const { resetUsers, emptyExtendFreeTrial, emptyAddNewUser } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
